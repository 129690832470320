/** @format */

:root {
    --darkTeal: #064663;
    --gold: #ecb365;
    --mk-bg0: #272822;
    --mk-bg1: #3e3d32;
    --mk-bg2: #75715e;
    --mk-fg0: #f8f8f2;
    --mk-fg1: #cfcfc2;
    --mk-yellow: #e6db74;
    --mk-orange: #fd971f;
    --mk-red: #f92672;
    --mk-magenta: #fd5ff0;
    --mk-violet: #ae81ff;
    --mk-blue: #66d9ef;
    --mk-cyan: #a1efe4;
    --mk-green: #a6e22e;
    --sns-blue: rgb(0, 55, 79);
    --fs-sm: calc(var(--bs-body-font-size) * 0.8);
}

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--sns-blue);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#root {
    min-height: 100vh;
    width: 100%;
}

#main {
    min-height: 100vh;
    max-width: 768px;
    box-shadow: 2px 2px 7px 0.25rem rgba(0, 0, 0, 0.6);
    background-color: var(--bs-light);
}

#main-dash {
    min-height: 100vh;
    max-width: 1024px;
    box-shadow: 2px 2px 7px 0.25rem rgba(0, 0, 0, 0.6);
    background-color: var(--bs-light);
}

.sign-in-container {
    min-height: 100vh;
}

.fs-sm { font-size: var(--fs-sm); }

.loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: var(--mk-fg0);
}

.spinner {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    /* background-color: #0cb1c4; */
    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.dropdown {
    position: absolute;
    top: 0;
    left: 0.5rem;
    padding: 0.5rem 0.05rem;
    margin-bottom: 3rem;
    background-color: var(--bs-light);
    /* border: 0.1rem solid var(--bs-gray-500); */
    border-radius: 0.3rem;
    /* box-shadow: 0 0 6px 3px rgba(var(--bs-gray), 0.3); */
    /* box-shadow: 0.05rem 0.05rem 0.03rem var(--bs-gray-800); */
    box-shadow: 0 0 3px 0.09rem rgba(var(--bs-primary-rgb), 0.5);
    z-index: 9992;
}

.list-view {
    position: static;
    padding: 0.5rem 0.05rem;
    margin-bottom: 3rem;
    background-color: var(--bs-light);
    border-radius: 0.3rem;
    box-shadow: 0 0 3px 0.09rem rgba(var(--bs-primary-rgb), 0.5);
}

.hover, 
.item-hover, 
.item, 
.hover-gold, 
.hover-gold-active,
.pointer {
    cursor: pointer;
}

.item, 
.item-hover {
    background-color: var(--bs-light);
    color: var(--bs-dark);
}

.item.active,
.item-hover.active {
    background-color: var(--bs-secondary);
    color: var(--bs-light);
}

.item:hover, 
.item-hover:hover, 
.item-hover.active:hover, 
.item-hover.item-id:hover,
.hover.primary:hover {
    background-color: var(--bs-primary) !important;
    color: var(--bs-light) !important;
}

.hover.warning:hover {
    background-color: var(--bs-warning) !important;
    color: var(--bs-light) !important;
}

.hover.gold:hover {
    background-color: var(--gold) !important;
    color: var(--bs-dark) !important;
}

.item-id {
    font-family: 'Courier New', Courier, monospace;
    color: var(--bs-pink);
}

.item-id.active {
    color: var(--bs-light);
}

.item-id-hover {
    font-family: 'Courier New', Courier, monospace;
    color: var(--bs-light);
}

.hover-gold-active {
    background-color: var(--mk-fg1);
}

.hover-gold:hover, .hover-gold-active:hover {
    background-color: var(--gold);
}

.noselect {
    -webkit-touch-callout: none;    /* iOS Safari */
    -webkit-user-select: none;      /* Safari */
    -khtml-user-select: none;       /* Konqueror HTML */
    -moz-user-select: none;         /* Old versions of Firefox */
    -ms-user-select: none;          /* Internet Explorer/Edge */
    user-select: none;              /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.modal-action {
    color: var(--mk-fg1);
    padding: 0.1rem;
    margin-left: 0.2rem;
    cursor: pointer;
}

.modal-action:hover {
    color: var(--bs-primary);
}

.date-picker {
    position: absolute;
    padding: 0.2rem;
    background-color: var(--bs-light);
    border: 0.2rem solid var(--mk-fg1);
    border-radius: 0.3rem;
    box-shadow: 0 0 6px 3px rgba(var(--bs-secondary-rgb), 0.3);
    top: 0;
    left: 1rem;
    z-index: 9992;
}

.date-head {
    color: var(--mk-fg0);
    background-color: var(--bs-secondary);
    justify-content: center;
    border-radius: 0.3rem;
    margin-bottom: 0.3rem;
}

.date-head div {
    width: 1.8rem;
    padding: 0.1rem;
    text-align: center;
}

.date-row div {
    width: 1.8rem;
    padding: 0.1rem;
    color: var(--mk-bg2);
    /* background-color: var(--bs-accent); */
    justify-content: center;
    text-align: center;
    cursor: pointer;
    border-radius: 0.3rem;
}

.date-row div:hover {
    /* background-color: var(--gold); */
    background-color: rgba(var(--bs-success-rgb), 0.7);
    color: var(--mk-fg0);
}

.date-row .active {
    color: var(--mk-fg0);
    background-color: var(--bs-success);
}

.date-row div.null {
    background-color: var(--mk-fg0);
    color: var(--mk-fg0);
    cursor: default;
}

.date-row div.null:hover {
    background-color: var(--mk-fg0);
    color: var(--mk-fg0);
}

.form-input {
    display: block;
    border-style: none;
    width: 100%;
    border-radius: 0;
    padding: 0.6rem 0.5rem 0.6rem 0.5rem;
    background-color: var(--bs-light);
    color: var(--bs-dark);
    border-bottom: 0.1rem solid var(--bs-secondary) !important;
}

.form-input:focus {
    outline: none !important;
    box-shadow: 0px;
    /* background-color: #30475eaa; */
    background-color: rgba(var(--bs-secondary-rgb), 0.18);
}

.form-input.valid {
    border-bottom: 0.06rem solid var(--bs-success);
}

.form-input.invalid {
    border-bottom: 0.06rem solid var(--bs-danger);
}

.form-invalid {
    color: var(--bs-danger);
    font-size: var(--fs-sm);
}

.form-valid {
    color: var(--bs-success);
    font-size: var(--fs-sm);
}

.form-floating-icon {
    position: absolute !important;
    font-size: calc(var(--bs-body-font-size) * 1.2);
    top: calc(var(--bs-body-font-size) * 0.3);
    right: calc(var(--bs-body-font-size) * 0.5);
}

.form-final {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    border-style: none;
    width: 100%;
    border-radius: 0;
    padding: 0.6rem 0.5rem;
    background-color: var(--bs-secondary);
    color: var(--bs-light);
    cursor: pointer;
    border-radius: 0.375rem;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
    white-space: nowrap;
    overflow: hidden;
}

.form-final:hover {
    /* box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.5); */
    box-shadow: inset 0 0 0 3rem rgba(0, 0, 0, 0.18);
}

.form-final-value {
    flex-grow: 1;
    min-width: 0; /*fix flex overflow issue*/
    /* max-width: 50%; */
}

.form-final-icon {
    display: none;
    background-color: var(--bs-dark);
    border-radius: 50%;
    padding: 0.1rem;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 17px rgba(var(--bs-light-rgb), 0.6) ;
}

.form-final:hover .form-final-icon {
    display: flex;
    background-color: var(--bs-danger);
}

.am-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(var(--bs-dark-rgb), 0.6);
    width: 100vw;
    height: 100vh;
    padding: 3rem;
}

.am-modal-container {
    position: relative;
    background-color: var(--bs-light);
    height: 100%;
    padding: 2rem;
    border-radius: 0.3rem;
    box-shadow: 2px 2px 7px 0.25rem rgba(0, 0, 0, 0.6);
    overflow: auto;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}